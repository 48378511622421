.challenge-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.challenge-card {
    padding: 15px;
    box-shadow: 1px 2px 10px 2px #ccc;
    width: 300px;
    border-radius: 10px;
    margin: 18px;
    background: antiquewhite;
    cursor: pointer;
}

.challenge-card > p {
    color: #c17846;
    font-weight: bold;
}

.challenge-img {
    margin-top: 10px;
    width: 100%;
    border-radius: 10px;
    height: auto;
}

.searchBox {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.searchInp {
    width: 60%;
    height: 24px;
}

.searchBtn {
    background: antiquewhite;
    cursor: pointer;
}
