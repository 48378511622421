.navbar {
    box-shadow:  rgb(204 204 204) 6px 2px 14px 2px;
    display: flex;
    align-items: center;
    min-height: 62px;
}

.nav {
    display: flex;
    /* flex-grow: 1; */
    align-items: center;
    cursor: pointer;
    width: fit-content;
    /* position: absolute; */
}

.heading {
    flex-grow: 1;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #c17846;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
  }
  
  .modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    width: 310px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  #yt {
    /* Your styles here */
    width: 100% !important;
    /* margin: 15px 0 !important; */
  }
  
  .content-container {
    margin: 15px 0 !important;
  }

  .quiz-card {
    background: antiquewhite;
    padding: 20px;
    border-radius: 15px;
  }

  .quiz-option {
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
  }

  .active-quiz-option {
    background: #cb7c46;
    font-weight: bolder;
    color: white;
  }

  .inactive-quiz-option {
    background: #ebc3a8;
    font-weight: 500;
  }

  /* Basic styling for the cross button */
.cross-button {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/* Styling for horizontal line (the top line of the cross) */
.horizontal-line {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #957259;
  transform: rotate(45deg);
}

/* Styling for vertical line (the right line of the cross) */
.vertical-line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 45%;
  width: 2px;
  background-color: #957259;
  transform: rotate(45deg);}

/* Optional: Hover effect to make the lines change color on hover */
.cross-button:hover .horizontal-line,
.cross-button:hover .vertical-line {
  background-color: red; /* Change to your preferred color on hover */
}


  
